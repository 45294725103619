<template>
    <Overview 
        :moduleName="$tc('navigation.' + this.$route.name, 1)"
        :moduleNamePlural="$tc('navigation.' + this.$route.name, 2)"
        :sort="'name'"
        :itemService=itemService
        :itemFormRef=itemFormRef
        ref="overview"
    >
        <template #columns>
            <Column field="name" :header="$t('overview.name')" :sortable="true"></Column>
            <!-- <Column field="has_nmbrs_token" :header="$t('overview.user_has_nmbrs')">
                <template #body="slotProps">
                    <i :class="'fa fa-' + (slotProps.data.has_nmbrs_token ? 'check' : 'close')"></i>
                </template>
            </Column> -->
        </template>

        <template #form>
            <CustomerForm ref="ItemForm" :itemData="item" />
        </template>

    </Overview>
  <div class="footer-buttons mb-2">
    <!--<button type="button" class="btn action-button" @click="connectToNMBRS">{{ $t('form.add', [$tc('navigation.' + this.$route.name, 1)]) }}</button>-->
     <button type="button" class="btn action-button" data-bs-toggle="modal" data-bs-target="#formModal" @click="$refs.overview.addItem()">{{ $t('form.add', [$tc('navigation.' + this.$route.name, 1)]) }}</button>
  </div>
</template>

<script>
import store from '@/store/user'
import Overview from '@/components/Overview.vue';
import Column from 'primevue/column';
import customerService from '@/services/CustomerService.js';
import CustomerForm from '@/components/forms/CustomerForm.vue';

export default {
    name: 'Customers',
    components: {
        Overview,
        Column,
        CustomerForm
    },
    data() {
        return {
            item: {},
            itemFormRef: null,
            itemService: customerService,
        }
    },
    methods: {
        next() {
            let tabs = document.querySelectorAll("#formModal ul.nav-tabs .nav-link");
        },
        connectToNMBRS(){
            customerService.connectToNMBRS(0).then(response => {
                if(response.data && typeof response.data == 'string'){
                    window.location.href = response.data;
                }
                else{
                    this.$toast.error("Invalid response, see console");
                    console.log('invalid response', response);
                }
            }).catch(error => {
                this.toastError(error)
                console.log('error', error)
            });
        }
    },
    mounted() {
        this.itemFormRef = this.$refs.ItemForm;
        if(!store.getters.getIsHR){
            this.$router.push({
                name: "Home"
            });
        }
    }
}
</script>