<template>
    <!--<div class="row">
        <label for="name" class="col-sm-3 col-form-label required-label">{{$t('overview.name')}}</label>
        <div class="col-sm-9">
            <input v-model="customer.name" type="text" id="name" />
        </div>
    </div>-->
    <div class="row">
        <label for="env" class="col-sm-3 col-form-label required-label">{{$t('form.environment')}}</label>
        <div class="col-sm-9">
            <select v-model="customer.domain" id="env">
                <option v-for="env in environments" :key="env.id" :value="env.id">{{ env.name }}</option>
            </select>
        </div>
    </div>
    <div class="row">
        <label for="debtor_id" class="col-sm-3 col-form-label required-label">{{$t('navigation.debtors')}}</label>
        <div class="col-sm-9">
            <select v-model="customer.debtor_id" id="debtor_id">
                <option :value="null" disabled>{{ $t('form.select') }}</option>
                <option v-for="debtor in debtors" :key="debtor.id" :value="debtor.id">{{ debtor.name }}</option>
            </select>
        </div>
    </div>
    <div class="row">
        <label for="use_gwi" class="col-sm-3 pe-0 col-form-label">{{$t('form.use_gwi')}}</label>
        <input type="checkbox" id="use_gwi" class="form-check-input p-0 mt-3 ms-3" v-model="customer.has_gwi" :true-value="1" :false-value="0" @change="setDefaultGWI" />
    </div>
    <div class="row" v-show="customer.has_gwi">
        <label for="use_gwi" class="col-sm-3 pe-0 col-form-label">{{$t('form.use_gwi_howmany')}}</label>
        <div class="col-sm-9">
            <input id='fwhc2' type="number" step="0.0001" v-model="customer.holiday_pay_hourly_percentage" />
        </div>
    </div>
    <div class="row">
        <label for="use_refrence" class="col-sm-3 pe-0 col-form-label">{{$t('form.use_refrence')}}</label>
        <input type="checkbox" id="use_refrence" class="form-check-input p-0 mt-3 ms-3" v-model="customer.use_refrence" :true-value="1" :false-value="0"  />
    </div>
    <div class="row">
        <label for="use_skilled_worker" class="col-sm-3 pe-0 col-form-label">{{$t('form.use_skilled_worker')}}</label>
        <input type="checkbox" id="use_skilled_worker" class="form-check-input p-0 mt-3 ms-3" v-model="customer.use_skilled_worker" :true-value="1" :false-value="0" />
    </div>
    <!--<br>
    <div class="row justify-content-end me-2" v-show="isEditing && !customer.has_nmbrs_token">
        <button class="btn action-button w-50" type="button" @click="connectToNMBRS"> {{ $t('company.make_nmbrs') }} </button>
    </div>-->
</template>

<script>
    import debtorService from '@/services/DebtorService';
    export default {
        name: 'CustomerForm',
        props: ['itemData'],
        data() {
            return {
                customer: {
                    // 'name': '',
                    'use_refrence': false,
                    'use_skilled_worker': false,
                    'debtor_id': null,
                    'domain': 'ifiac.nmbrs.nl'
                },
                environments: [
                    {id: 'ifiac.nmbrs.nl', name: 'Ifiac'},
                    {id: 'ibeo.nmbrs.nl', name: 'Ibeo'}
                ],
                debtors: [],
                isEditing: false,
                submitted: false,
                loading: false,
                invalid: {}
            }
        },
        watch: {
            itemData: function (val) {
                this.customer = val
            },
        },
        methods: {
            validate(){
                const required = document.querySelectorAll('#env, #debtor_id');
                let isValid = true;
                required.forEach(field => {
                    if(!field.value || field.value == this.$t('form.select')){
                        isValid = false;
                        field.classList.add('is-invalid');
                    }
                    else{
                        field.classList.remove('is-invalid');
                    }
                });
                if(!isValid){
                    alert(this.$t('errors.empty_fields'));
                }
                else if(!this.customer.has_gwi){
                    this.customer.holiday_pay_hourly_percentage = null;
                }
                return isValid;
            },
            getData() {
                return this.customer;
            },
            setData(data) {
                this.customer = data;
                this.customer.has_gwi = data.holiday_pay_hourly_percentage != null ? 1 : 0;
                this.isEditing = true
            },
            reset(shouldLoadData = true) {
                this.customer = {
                    // 'name': '',
                    'use_refrence': false,
                    'use_skilled_worker': false,
                    'debtor_id': null,
                    'domain': 'ifiac.nmbrs.nl',
                    'has_gwi': 0,
                    'holiday_pay_hourly_percentage': null
                }
                if(shouldLoadData && this.debtors.length == 0){
                    this.getDebtors();
                }
                this.isEditing = false
                this.loading = false
                this.invalid = {}
            },

            getDebtors(){
                debtorService.index().then(response => {
                    this.debtors = response.data;
                }).catch(error => {
                    console.log('error', error)
                })
            },

            setDefaultGWI(){
                if(this.customer.has_gwi) this.customer.holiday_pay_hourly_percentage = 19.4912;
            }
        },
        mounted () {
            this.reset(false)
        }
    }
</script>
